import React, { Component } from "react";
import { Typography, withStyles, AppBar, Toolbar } from "@material-ui/core";
import gql from "graphql-tag";
import { Helmet } from "react-helmet";
import { withApollo } from "react-apollo";
import taabiLogo from "../../static/png/taabi_logo.png"
import favicon from "../../static/svg/favicon.svg"

const GET_DOMAIN_CONFIG = gql`
  query($domain: String!) {
    domainConfiguration(domain: $domain) {
      header {
        title
      }
      page {
        logo
        navbarConfig {
          logo
          title
        }
      }
    }
  }
`;

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#ffffff',
    color: '#333333',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    // width: "99vw",
  },
  toolbar: {
    minHeight: 80,
    padding: 0,
    margin: 0,
  },
  title: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: '1.2rem',
    letterSpacing: '0.5px',
    marginLeft: theme.spacing(2),
  },
  logo: {
    maxHeight: 40,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
});

class NavBar extends Component {
  state = {
    config: "",
  };

  componentDidMount() {
    this.getConfig();
  }

  getConfig = async () => {
    const response = await this.props.client.query({
      query: GET_DOMAIN_CONFIG,
      variables: { domain: window.location.host },
    });

    if (response) {
      this.setState({ config: response.data.domainConfiguration });
    }
  };

  render() {
    const { classes } = this.props;
    const { config } = this.state;

    return (
      <>
        {config !== "" && (
          <>
            <Helmet>
              <title>{localStorage.getItem("title")}</title>
              <link rel="shortcut icon" type="image/svg+xml" href={favicon} />
            </Helmet>
            <AppBar position="static" className={classes.appBar} elevation={0}>
              <Toolbar className={classes.toolbar}>
                {config?.page?.navbarConfig?.logo ? (
                  <img src={config.page.navbarConfig.logo} alt="Taabi" className={classes.logo} />
                ):
                <Typography variant="h4" style={{marginTop: "7px"}}>
                  <img src={taabiLogo} alt="Taabi" className={classes.logo} />
                </Typography>}
              </Toolbar>
            </AppBar>
          </>
        )}
      </>
    );
  }
}

export default withApollo(withStyles(styles)(NavBar));