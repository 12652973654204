export const ClustererIcon = `<svg width="88" height="54" viewBox="0 0 88 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_201_3067)">
<mask id="path-1-inside-1_201_3067" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 4C13.3726 4 8 9.37258 8 16V23C8 29.6274 13.3726 35 20 35H37.8416L42.4152 40.9413C43.2158 41.9813 44.7842 41.9813 45.5848 40.9413L50.1584 35H68C74.6274 35 80 29.6274 80 23V16C80 9.37258 74.6274 4 68 4H20Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 4C13.3726 4 8 9.37258 8 16V23C8 29.6274 13.3726 35 20 35H37.8416L42.4152 40.9413C43.2158 41.9813 44.7842 41.9813 45.5848 40.9413L50.1584 35H68C74.6274 35 80 29.6274 80 23V16C80 9.37258 74.6274 4 68 4H20Z" fill="#F6F9FC"/>
<path d="M37.8416 35L38.634 34.39L38.3338 34H37.8416V35ZM42.4152 40.9413L43.2076 40.3313H43.2076L42.4152 40.9413ZM45.5848 40.9413L44.7924 40.3313H44.7924L45.5848 40.9413ZM50.1584 35V34H49.6662L49.366 34.39L50.1584 35ZM9 16C9 9.92487 13.9249 5 20 5V3C12.8203 3 7 8.8203 7 16H9ZM9 23V16H7V23H9ZM20 34C13.9249 34 9 29.0751 9 23H7C7 30.1797 12.8203 36 20 36V34ZM37.8416 34H20V36H37.8416V34ZM43.2076 40.3313L38.634 34.39L37.0492 35.61L41.6228 41.5513L43.2076 40.3313ZM44.7924 40.3313C44.3921 40.8513 43.6079 40.8513 43.2076 40.3313L41.6228 41.5513C42.8237 43.1113 45.1763 43.1113 46.3772 41.5513L44.7924 40.3313ZM49.366 34.39L44.7924 40.3313L46.3772 41.5513L50.9508 35.61L49.366 34.39ZM68 34H50.1584V36H68V34ZM79 23C79 29.0751 74.0751 34 68 34V36C75.1797 36 81 30.1797 81 23H79ZM79 16V23H81V16H79ZM68 5C74.0751 5 79 9.92487 79 16H81C81 8.8203 75.1797 3 68 3V5ZM20 5H68V3H20V5Z" fill="#7F92A4" mask="url(#path-1-inside-1_201_3067)"/>
</g>
<circle cx="24" cy="20" r="10" fill="#1C85E8"/>
<g clip-path="url(#clip0_201_3067)">
<path d="M20.25 18H21.5V17C21.5 16.45 21.95 16 22.5 16H28.5C29.05 16 29.5 16.45 29.5 17V21.5C29.5 22.05 29.05 22.5 28.5 22.5C28.5 23.33 27.83 24 27 24C26.17 24 25.5 23.33 25.5 22.5H22.5C22.5 23.33 21.83 24 21 24C20.17 24 19.5 23.33 19.5 22.5H19C18.725 22.5 18.5 22.275 18.5 22V20.335C18.5 20.12 18.57 19.91 18.7 19.735L19.85 18.2C19.945 18.075 20.095 18 20.25 18ZM27 23C27.275 23 27.5 22.775 27.5 22.5C27.5 22.225 27.275 22 27 22C26.725 22 26.5 22.225 26.5 22.5C26.5 22.775 26.725 23 27 23ZM20.25 18.75L19.27 20H21.5V18.75H20.25ZM21 23C21.275 23 21.5 22.775 21.5 22.5C21.5 22.225 21.275 22 21 22C20.725 22 20.5 22.225 20.5 22.5C20.5 22.775 20.725 23 21 23Z" fill="#F6F9FC"/>
</g>
<defs>
<filter id="filter0_d_201_3067" x="0" y="0" width="88" height="53.7212" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.329653 0 0 0 0 0.377523 0 0 0 0 0.420833 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_3067"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_3067" result="shape"/>
</filter>
<clipPath id="clip0_201_3067">
<rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 30 14)"/>
</clipPath>
</defs>
</svg>
`;

export const ClustererIconDark = `<svg width="88" height="54" viewBox="0 0 88 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_201_3067)">
<mask id="path-1-inside-1_201_3067" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 4C13.3726 4 8 9.37258 8 16V23C8 29.6274 13.3726 35 20 35H37.8416L42.4152 40.9413C43.2158 41.9813 44.7842 41.9813 45.5848 40.9413L50.1584 35H68C74.6274 35 80 29.6274 80 23V16C80 9.37258 74.6274 4 68 4H20Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 4C13.3726 4 8 9.37258 8 16V23C8 29.6274 13.3726 35 20 35H37.8416L42.4152 40.9413C43.2158 41.9813 44.7842 41.9813 45.5848 40.9413L50.1584 35H68C74.6274 35 80 29.6274 80 23V16C80 9.37258 74.6274 4 68 4H20Z" fill="#212121"/>
<path d="M37.8416 35L38.634 34.39L38.3338 34H37.8416V35ZM42.4152 40.9413L43.2076 40.3313H43.2076L42.4152 40.9413ZM45.5848 40.9413L44.7924 40.3313H44.7924L45.5848 40.9413ZM50.1584 35V34H49.6662L49.366 34.39L50.1584 35ZM9 16C9 9.92487 13.9249 5 20 5V3C12.8203 3 7 8.8203 7 16H9ZM9 23V16H7V23H9ZM20 34C13.9249 34 9 29.0751 9 23H7C7 30.1797 12.8203 36 20 36V34ZM37.8416 34H20V36H37.8416V34ZM43.2076 40.3313L38.634 34.39L37.0492 35.61L41.6228 41.5513L43.2076 40.3313ZM44.7924 40.3313C44.3921 40.8513 43.6079 40.8513 43.2076 40.3313L41.6228 41.5513C42.8237 43.1113 45.1763 43.1113 46.3772 41.5513L44.7924 40.3313ZM49.366 34.39L44.7924 40.3313L46.3772 41.5513L50.9508 35.61L49.366 34.39ZM68 34H50.1584V36H68V34ZM79 23C79 29.0751 74.0751 34 68 34V36C75.1797 36 81 30.1797 81 23H79ZM79 16V23H81V16H79ZM68 5C74.0751 5 79 9.92487 79 16H81C81 8.8203 75.1797 3 68 3V5ZM20 5H68V3H20V5Z" fill="#D6E1E6" mask="url(#path-1-inside-1_201_3067)"/>
</g>
<circle cx="24" cy="20" r="10" fill="#1C85E8"/>
<g clip-path="url(#clip0_201_3067)">
<path d="M20.25 18H21.5V17C21.5 16.45 21.95 16 22.5 16H28.5C29.05 16 29.5 16.45 29.5 17V21.5C29.5 22.05 29.05 22.5 28.5 22.5C28.5 23.33 27.83 24 27 24C26.17 24 25.5 23.33 25.5 22.5H22.5C22.5 23.33 21.83 24 21 24C20.17 24 19.5 23.33 19.5 22.5H19C18.725 22.5 18.5 22.275 18.5 22V20.335C18.5 20.12 18.57 19.91 18.7 19.735L19.85 18.2C19.945 18.075 20.095 18 20.25 18ZM27 23C27.275 23 27.5 22.775 27.5 22.5C27.5 22.225 27.275 22 27 22C26.725 22 26.5 22.225 26.5 22.5C26.5 22.775 26.725 23 27 23ZM20.25 18.75L19.27 20H21.5V18.75H20.25ZM21 23C21.275 23 21.5 22.775 21.5 22.5C21.5 22.225 21.275 22 21 22C20.725 22 20.5 22.225 20.5 22.5C20.5 22.775 20.725 23 21 23Z" fill="#F6F9FC"/>
</g>
<defs>
<filter id="filter0_d_201_3067" x="0" y="0" width="88" height="53.7212" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.329653 0 0 0 0 0.377523 0 0 0 0 0.420833 0 0 0 0.24 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_201_3067"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_201_3067" result="shape"/>
</filter>
<clipPath id="clip0_201_3067">
<rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 30 14)"/>
</clipPath>
</defs>
</svg>
`;
