import React from "react";
import { Box, Typography, LinearProgress, IconButton, Icon } from "@material-ui/core";
import {
  Close,
  LocalGasStation,
  Speed,
  LocationOn,
  DirectionsCar,
  TrendingUp,
  Timer,
  Timer10Sharp,
  CalendarToday,
  LocalShipping,
  AccessTime,
} from "@material-ui/icons";
import { getFormattedDuration, getFormattedTime } from "Map/utils";
import moment from "moment";
import DgSetIconSVG from '../../static/svg/DgSetIcon.svg';

const VehiclePopupCard = ({
  vehicleNumber,
  vehicleModel,
  status,
  speed,
  address,
  timestamp,
  since
}) => {
  const statusColor =
    status === "offline"
      ? "#7f92a4"
      : status === "nogps"
      ? "#192e45"
      : status === "halt"
      ? "rgb(255 128 130)"
      : status === "idle"
      ? "rgb(255 194 89)"
      : status === "no data"
      ? "rgb(39 63 90)"
      : "rgb(44 200 167)";

  return (
    <Box
      style={{
        backgroundColor: "#fff",
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid rgb(42 42 42 / 59%)",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        width: 500,
        position: "relative",
        lineHeight: 1.5,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" alignItems="center" ml="2px">
          <Typography
            variant="h5"
            style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)", display: "flex" }}
          > 
          {vehicleModel !== "DG Set" ? <Icon style={{marginRight: "5px", width: "30px"}}>
                <LocalShipping />
              </Icon>
              : <Box
                  component="img"
                  src={DgSetIconSVG}
                  alt="Generator Icon"
                  style={{
                    width: "30px",
                    height: "auto",
                    margin: "auto",
                    display: "inline"
                  }}
                />}
            {vehicleNumber}
          </Typography>
          <TrendingUp style={{ color: statusColor, marginLeft: "0.5rem" }} />
          <Typography
            variant="body1"
            style={{
              color: statusColor,
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            {status} &nbsp;
          </Typography>
          {!(status === 'nogps' || status === 'offline'|| status === 'no data') && <Typography variant="body1" style={{fontFamily:"sans-serif", fontWeight: 500}}>
            since {getFormattedDuration(since)}
          </Typography>}
        </Box>
        <IconButton
          style={{ position: "absolute", top: 5, right: 5 }}
          id="popup-close-btn"
        >
          <Close />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <DirectionsCar style={{ marginRight: 5, fontSize: 23 }} />
        <Typography
          variant="body1"
          style={{ fontWeight: "500", textTransform: "capitalize" }}
        >
          {vehicleModel}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <LocationOn style={{ marginRight: 5, fontSize: 23 }} />
        <Typography variant="body2" style={{ fontWeight: "500" }}>
          {address}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <Speed style={{ marginRight: 5, fontSize: 23 }} />
        <Typography variant="body2" style={{ fontWeight: "500" }}>
          {speed} kmph
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <AccessTime style={{ marginRight: 5, fontSize: 22 }} />
        <Typography variant="body2" style={{ fontWeight: "500" }}>
          {"Last Updated " +
            getFormattedDuration(moment().unix() - timestamp) +
            " ago"}
        </Typography>
      </Box>
    </Box>
  );
};

export default VehiclePopupCard;
