import React, { useState } from "react";
import Live from "../../pages/LiveVehicle/Live";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import decode from "jwt-decode";

function HomePage(props) {
  try {
    if(!(localStorage.getItem("token") ==="null")) decode(localStorage.getItem("token"))
  } catch (error) {
    return <ErrorPage {...props} isInvalid={true}/>;
  }
  if (
    
    localStorage.getItem("token") ==="null" ||
    localStorage.getItem("exp") < Math.round(new Date().getTime() / 1000)
  ) {
    return <ErrorPage {...props} />;
  } else {
    return <Live {...props} />;
  }
}

export default HomePage;
