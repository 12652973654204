import React, { useState, useEffect } from 'react';
import getApolloClient from './apollo/index';
import HomePage from './components/HomePage/index';
import { ApolloProvider } from 'react-apollo';
import { Box, CircularProgress, Fade, Typography } from '@material-ui/core';

function App() {
  const [client, setClient] = useState(null);  // State to hold the Apollo Client

  // Fetch the Apollo Client asynchronously when the component mounts
  useEffect(() => {
    async function initializeApolloClient() {
      const apolloClient = await getApolloClient();
      setClient(apolloClient);
    }

    initializeApolloClient();
    
    document.body.style.margin = '0';
    document.body.style.padding = '0';
  }, []);

  // Only render ApolloProvider when the client is ready
  if (!client) {
    return <Fade in={true} timeout={500}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed', // Ensure it covers the entire viewport
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
        zIndex: 9999, // Make sure it's on top
      }}
    >
      <CircularProgress size={80} color="primary" />
      <Typography variant="h5" sx={{ marginTop: 2, fontWeight: 'bold', color: '#333' }}>
        Loading...
      </Typography>
      <Typography variant="body1" sx={{ color: '#555' }}>
        Please wait while we load your content.
      </Typography>
    </Box>
  </Fade>
  }

  return (
    <ApolloProvider client={client}>
      <HomePage />
    </ApolloProvider>
  );
}

export default App;
