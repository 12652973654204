import './CustomPopup.css'
import {getFormattedTime} from '../utils'
import ReactDOM from 'react-dom';
import React from 'react';
import VehiclePopupCard from './VehiclePopupCard';

const container = document.createElement('div')
const header = document.createElement('div')
header.classList.add('popup-header')
const body = document.createElement('div')
container.appendChild(header)
container.appendChild(body)

export default function getCustomPopup(google) {
  return class CustomPopup extends google.maps.OverlayView {
    constructor(position) {
      super()
      this.position = position
      this.previousPosition = position;
      this.header = header
      this.body = body
      container.classList.add('popup-bubble-content', 'custom-popup-container');

      const pixelOffset = document.createElement('div')
      pixelOffset.classList.add('popup-bubble-anchor')
      pixelOffset.appendChild(container)
      pixelOffset.style.pointerEvents = 'auto'
      container.style.pointerEvents = 'auto'

      this.anchor = document.createElement('div')
      this.anchor.classList.add('popup-tip-anchor')
      this.anchor.appendChild(pixelOffset)
      this.anchor.style.pointerEvents = 'none'
      // Optionally stop clicks, etc., from bubbling up to the map.
      // this.stopEventPropagation()
    }

    onAdd = () => {
      this.getPanes().overlayImage.appendChild(this.anchor)
      // this.stopEventPropagation()
    }

    onRemove = () => {
      if (this.anchor.parentElement) {
        this.anchor.parentElement.removeChild(this.anchor)
      }
    }

    getStatus = (vehicleObj) => {
      return  vehicleObj.isOffline
        ? "offline"
        : vehicleObj.isNoGps
        ? "nogps"
        : vehicleObj.haltStatus
        ? "halt"
        : vehicleObj.idlingStatus
        ? "idle"
        : "running";
    }

    setPosition = (position, vehicleObj, vehicleCoreObj) => {
      this.previousPosition = this.position;
      //delay for proper postitioning
      this.setPopupData({vehicleNumber: vehicleCoreObj.vehicleNumber, vehicleModel: vehicleCoreObj.vehicleType
        , status: this.getStatus(vehicleObj), speed: vehicleObj.speed, timestamp: vehicleObj.timestamp, address: vehicleObj.address, since: vehicleObj.since})
      setTimeout(()=>{
        this.position = position; 
        this.animateToNewPosition(this.previousPosition, this.position, 3000);
      }, 1000)
    }

    // setPopupData = ({
    //   vehicleNumber,
    //   vehicleModel,
    //   status,
    //   speed,
    //   timestamp,
    //   address
    // }) => {
    //   this.header.innerHTML = `
    //     <div style="background-color: #4a90e2; color: white; padding: 10px; border-radius: 5px 5px 0 0; font-size: 16px; font-weight: bold; position: relative;">
    //       Vehicle ${vehicleNumber}
    //       <button id="popup-close-btn" style="position: absolute; right: 10px; top: 2px; background-color: transparent; border: none; color: white; font-size: 30px; cursor: pointer;">&times;</button>
    //     </div>
    //   `;
  
    //   this.body.innerHTML = `
    //     <div style="padding: 12px; background-color: #f9f9f9; border-radius: 0 0 5px 5px; font-size: 14px;">
    //       <div style="margin-bottom: 8px;">
    //         <i class="fas fa-car" style="color: #4a90e2; margin-right: 5px;"></i>
    //         <span style="font-weight: bold;  text-transform: capitalize;">${status}</span>
    //       </div>
    //       <div style="margin-bottom: 8px;">
    //         <i class="fas fa-tachometer-alt" style="color: #4a90e2; margin-right: 5px;"></i>
    //         <span style="font-weight: bold;">Speed:</span> ${speed} km/h
    //       </div>
    //       <div style="margin-bottom: 8px;">
    //         <div style="display: flex; align-items: flex-start;">
    //           <i class="fas fa-map-marker-alt" style="color: #4a90e2; margin-right: 5px; flex-shrink: 0; margin-top: 3px;"></i>
    //           <div>
    //             <span style="font-weight: bold;">Location:</span>
    //             <span style="margin-top: 2px; word-wrap: break-word;">${address || 'N/A'}</span>
    //           </div>
    //         </div>
    //       </div>
    //       <div>
    //         <i class="far fa-clock" style="color: #4a90e2; margin-right: 5px;"></i>
    //         <span style="font-weight: bold;">Last tracked:</span> 
    //         <span style="font-size: 12px;">${getFormattedTime(timestamp, 'MMMM Do YYYY, h:mm:ss a')}</span>
    //       </div>
    //     </div>
    //   `;
    //   const closeButton = this.header.querySelector('#popup-close-btn');
    //   closeButton.addEventListener('click', () => {        
    //     this.onRemove(); // Remove the popup
    //   });
    // }

    setPopupData = ({
      vehicleNumber,
      vehicleModel,
      status,
      speed,
      timestamp,
      address,
      since
    }) => {
      
      ReactDOM.render(
        <VehiclePopupCard 
          vehicleNumber={vehicleNumber}
          vehicleModel={vehicleModel}
          status={status}
          speed={speed}
          timestamp={timestamp}
          address={address}
          since={since}
        />, 
        container
      );
      const closeButton = container.querySelector('#popup-close-btn');
      if(closeButton){
      closeButton.addEventListener('click', () => {        
        this.onRemove(); // Remove the popup
      });
    }
    }



    //animate the popup(the animation is in accordance with marker so both move together)
    animateToNewPosition = (startPos, endPos, duration) => {
      const startTime = performance.now();
    
      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const durationRatio = elapsed / duration;
        const easingDurationRatio = 0.5 - Math.cos(durationRatio * Math.PI) / 2;
    
        if (durationRatio < 1) {
          
          const newLat = startPos.lat() + (endPos.lat() - startPos.lat()) * easingDurationRatio;
          const newLng = startPos.lng() + (endPos.lng() - startPos.lng()) * easingDurationRatio;
          
          const newPos = new google.maps.LatLng(newLat,  newLng);
    
          this.updatePosition(newPos);
    
          requestAnimationFrame(animate);
        } else {
          this.updatePosition(endPos);
          if (typeof this.completeCallback === "function") {
            this.completeCallback();
          }
        }
      };
    
      requestAnimationFrame(animate);
    }

    //update the position of popup
    updatePosition = (position) => {
      const divPosition = this.getProjection().fromLatLngToDivPixel(position);
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? 'block'
          : 'none';

      if (display === 'block') {
        this.anchor.style.left = divPosition.x + 'px';
        this.anchor.style.top = divPosition.y + 'px';
      }
      if (this.anchor.style.display !== display) {
        this.anchor.style.display = display;
      }
    }

    /** Called when the popup needs to draw itself. */
    // draw = () => {
    //   const divPosition = this.getProjection().fromLatLngToDivPixel(
    //     this.position
    //   )
    //   // Hide the popup when it is far out of view.
    //   const display =
    //     Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
    //       ? 'block'
    //       : 'none'

    //   if (display === 'block') {
    //     this.anchor.style.left = divPosition.x + 'px'
    //     this.anchor.style.top = divPosition.y + 'px'
    //   }
    //   if (this.anchor.style.display !== display) {
    //     this.anchor.style.display = display
    //   }
    // }

    draw = () => {
      this.updatePosition(this.position); // Initial drawing of the popup
    }

    /** Stops clicks/drags from bubbling up to the map. */
    stopEventPropagation = () => {
      const anchor = this.anchor
      anchor.style.cursor = 'auto'
    }
  }
}
