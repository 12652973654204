import React, { Component } from "react";
import NavBar from "../NavBar/NavBar";

class ErrorPage extends Component {
  render() {
    const { isInvalid } = this.props;
    return (
      <>
        <NavBar {...this.props} />
        <div style={styles.container}>
          <div style={styles.errorContent}>
            <h1 style={styles.heading}>
              {isInvalid ? "Invalid Token" : "Sorry !!! Token Expired"}
            </h1>
            <p style={styles.message}>
              {isInvalid
                ? "It looks like the token you provided is not valid. Please try again."
                : "The token has expired. You may need to request a new one."}
            </p>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f4f6f8",
  },
  errorContent: {
    textAlign: "center",
    backgroundColor: "#fff",
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    width: "100%",
    margin: "20px",
    marginTop: "7rem"
  },
  heading: {
    fontSize: "2.5rem",
    color: "#ff6b6b",
    marginBottom: "20px",
  },
  message: {
    fontSize: "1.2rem",
    color: "#6c757d",
  },
};

export default ErrorPage;
