import React from 'react';
import { Paper, Card, CardContent, Grid, Typography, Box, Chip, IconButton, Icon } from '@material-ui/core';
import { DirectionsCar, Speed, AccessTime, LocationOn, TrendingUp, Close, StarSharp, LocalShipping } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedDuration, getFormattedTime } from 'Map/utils';
import moment from 'moment';
import DgSetIconSVG from '../../static/svg/DgSetIcon.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    backgroundColor: '#ffffff',
    borderLeft: '10px solid #3f51b5',
    // fontWeight: "800"
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  vehicleNumber: {
    // fontWeight: 'bold',
    // color: '#3f51b5',
    color: '#061b2e',
    fontWeight: "800",
    fontSize: "1.4rem",
    marginLeft: "2px"
  },
  chip: {
    backgroundColor: '#e0e0e0',
    color: '#424242',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    color: '#061b2e',
    fontWeight: "500",
    fontSize: "1rem"
  },
  icon: {
    color: '#061b2e',
    marginRight: theme.spacing(1),
    fontWeight: "600"
  },
  speedText: {
    // color: '#2e7d32',
    color: '#061b2e',
    fontWeight: "500",
    fontSize: "1rem"
  },
  timeText: {
    // color: '#1565c0',
    color: '#061b2e',
    fontWeight: "400",
    fontSize: "1rem"
  },
  locationText: {
    color: '#061b2e',
    fontWeight: "500",
    fontSize: "1rem"
  },
}));

const VehicleCard = ({ vehicle, handleVehicleLiveTracking, selectedVehicle, handleCloseVehicleLiveTracking }) => {
  const classes = useStyles();
  const currentData = vehicle.device[vehicle.device.length -1 ];

  const status = currentData.isOffline
  ? "offline"
  : currentData.isNoGps
  ? "nogps"
  : currentData.haltStatus
  ? "halt"
  : currentData.idlingStatus
  ? "idle"
  : currentData.isNoData
  ? "no data"
  : "running";

  const borderColor = currentData.isOffline
  ? "#7f92a4"
  : currentData.isNoGps
  ? "#192e45"
  : currentData.haltStatus
  ? "rgb(255 128 130)"
  : currentData.idlingStatus
  ? "rgb(255 194 89)"
  : currentData.isNoData
  ? "rgb(39 63 90)"
  : "rgb(44 200 167)";

  return (
    <Paper 
      // elevation={selectedVehicle?.id ===vehicle.id ? 6 : 2} 
      elevation={2}
      style={{ 
        marginBottom: "16px", 
        borderRadius: "8px", 
        overflow: "hidden",
        ...(selectedVehicle?.id === vehicle.id && { border: "2px solid blue" }),
      }}
      onClick={() => {
        if(currentData.isNoData) return;
        handleVehicleLiveTracking(vehicle)
      }}
    >
      <Card className={classes.card} style={{borderColor: borderColor}}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6" className={classes.vehicleNumber} align='center' style={{display: "flex"}}>
              {vehicle.vehicleType !== "DG Set" ? <Icon style={{marginRight: "5px", width: "30px"}}>
                <LocalShipping />
              </Icon>
              : <Box
                  component="img"
                  src={DgSetIconSVG}
                  alt="Generator Icon"
                  sx={{
                    width: "30px",
                    height: "auto",
                    margin: "auto",
                    display: "inline",
                  }}
                />}
              {vehicle.vehicleNumber}
            </Typography>
            <Box display="flex">
              <Chip 
                // icon={<LocalShipping />} 
                label={vehicle.vehicleType || 'Unknown'} 
                size="small" 
                className={classes.chip}
                />
                <IconButton style={{margin: 0, padding: 0, width: "30px"}} onClick={(e)=>{
                  e.stopPropagation();
                  handleCloseVehicleLiveTracking();
                }}>
              {selectedVehicle?.id === vehicle.id && 
                  <Close/>
                }
                </IconButton>
            </Box>
              
          </Box>
          
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box className={classes.infoBox} >
                <TrendingUp className={classes.icon} style={{color: borderColor}}/>
                <Typography variant="body1" className={classes.speedText} style={{color: borderColor, textTransform: 'capitalize'}}>
                  {status} &nbsp;
                </Typography>
                {!(status === 'nogps' || status === 'offline' || status === 'no data') && <Typography style={{fontFamily:"sans-serif", fontWeight: 500}}>
                  since {getFormattedDuration(currentData.since)}
                </Typography>}
              </Box>
              <Box className={classes.infoBox}>
                <Speed className={classes.icon} />
                <Typography variant="body1" className={classes.speedText}>
                  {currentData.speed} km/h
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <Box className={classes.infoBox}>
                <LocationOn className={classes.icon} />
                <Typography variant="body1" className={classes.locationText} style={{ wordBreak: 'break-word' }}>
                  {currentData.address}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.infoBox} style={{marginBottom: 0}}>
                <AccessTime className={classes.icon} />
                <Typography variant="body1" className={classes.timeText} style={{fontWeight: 500}}>
                  {"Last Updated " +
                      getFormattedDuration(
                        moment().unix() - parseInt(currentData.timestamp),
                      ) +
                      " ago"}
                </Typography>
              </Box>
            </Grid>
            
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default VehicleCard;