import moment from "moment";

export function getIconSizeFromZoom(zoom, options) {
  const { square = false, sizeFactor = 1 } = options

  const dimensionRatio = square ? 1 : 0.5

  let width

  if (zoom >= 20) {
    width = 70
  } else if (zoom >= 19) {
    width = 65
  } else if (zoom >= 18) {
    width = 60
  } else if (zoom >= 17) {
    width = 55
  } else if (zoom >= 16) {
    width = 50
  } else if (zoom >= 15) {
    width = 45
  } else if (zoom >= 14) {
    width = 40
  } else if (zoom >= 13) {
    width = 35
  } else if (zoom >= 12) {
    width = 30
  } else if (zoom >= 10) {
    width = 25
  } else if (zoom >= 8) {
    width = 20
  } else if (zoom >= 6) {
    width = 15
  } else {
    width = 10
  }

  return {
    width: width * sizeFactor,
    height: width * dimensionRatio * sizeFactor
  }
}

export function getFrameRateFromZoom(zoom) {
  let frameRate
  if (zoom >= 20) {
    frameRate = 50
  } else if (zoom >= 19) {
    frameRate = 45
  } else if (zoom >= 18) {
    frameRate = 40
  } else if (zoom >= 17) {
    frameRate = 35
  } else if (zoom >= 16) {
    frameRate = 30
  } else if (zoom >= 15) {
    frameRate = 25
  } else if (zoom >= 14) {
    frameRate = 20
  } else if (zoom >= 13) {
    frameRate = 15
  } else if (zoom >= 12) {
    frameRate = 10
  } else if (zoom >= 11) {
    frameRate = 8
  } else if (zoom >= 10) {
    frameRate = 7
  } else if (zoom >= 9) {
    frameRate = 6
  } else if (zoom >= 8) {
    frameRate = 5
  } else if (zoom >= 7) {
    frameRate = 4
  } else if (zoom >= 6) {
    frameRate = 3
  } else if (zoom >= 5) {
    frameRate = 2
  } else {
    frameRate = 1
  }
  return frameRate
}

export function getFormattedTime(timestamp, format) {
  const date = new Date(timestamp);
  
  // Define format components
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Get individual components
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const weekday = days[date.getDay()];
  const hour = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Replace tokens in the format
  let formattedTime = format
    .replace('MMMM', month)
    .replace('Do', `${day}${getDaySuffix(day)}`)
    .replace('YYYY', year)
    .replace('h', hour)
    .replace('mm', minutes)
    .replace('ss', seconds)
    .replace('a', ampm.toLowerCase())
    .replace('A', ampm);    

  return `${month}, ${day} ${year}, ${hour}:${minutes}:${seconds} ${ampm}`;
}

function getDaySuffix(day) {
  if (day > 3 && day < 21) return 'th'; // for 11th to 20th
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}


export function getFormattedDuration(duration) {
  const momentDuration = moment.duration(duration, "s");
  const years = momentDuration.years();
  const months = momentDuration.months();
  const days = momentDuration.days();
  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  const seconds = momentDuration.seconds();

  const durationArray = [];

  if (years) {
    durationArray.push(`${years}y`);
  }

  if (months) {
    durationArray.push(`${months}mon`);
  }

  if (days) {
    durationArray.push(`${days}d`);
  }

  if (hours) {
    durationArray.push(`${hours}h`);
  }

  if (minutes) {
    durationArray.push(`${minutes}min`);
  }

  if (seconds) {
    durationArray.push(`${seconds}s`);
  }

  if (!durationArray.length) {
    durationArray.push("0s");
  }

  return durationArray.slice(0, 2).join(" ");
}
