import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  withStyles,
  Card,
  CardContent,
  Box,
  useTheme,
  useMediaQuery,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import NavBar from "../NavBar/NavBar";
import getCustomMarker from "../../Map/MapUtils/CustomMarker";
import getMultiLine from "../../Map/MapUtils/MultiLine";
import { DEVICE_LOCATION } from "../../graphql/subscriptions";
import withGoogleMaps from "../../hoc/withGoogleMaps";
import VehicleCard from "./VehicleCard";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import clusterImg from "../../static/png/green-cluster.png";
import getCustomPopup from "../../Map/CustomPopup/CustomPopup";
import { ClustererIcon } from "./ClusterIcon";
const Map = React.lazy(() => import("../../Map/Map"));

const GET_VEHICLE_DETAIL = gql`
  query ($id: String) {
    getVehicleDetail(deviceUniqueId_fk: $id) {
      vehicleType
    }
  }
`;

const GET_LATEST_LOCATION = gql`
  query ($id: String!) {
    latestLocation: getDeviceLatestLocation(deviceId: $id) {
      latitude
      longitude
      vehicleNumber
      address
      speed
      timestamp
      extBatVol
      haltStatus
      idlingStatus
      isNoGps
      isOverspeed
      isPrimaryBattery
      isOffline
      isNoData
    }
  }
`;

const GET_MUTLTI_DEVICES_LATEST_LOCATION = gql`
  query GetMultiDevicesLatestLocation($deviceIds: [String!]!) {
    getMultiDevicesLatestLocation(deviceIds: $deviceIds) {
      uniqueId
      latitude
      longitude
      vehicleNumber
      address
      speed
      timestamp
      extBatVol
      haltStatus
      idlingStatus
      isNoGps
      isOverspeed
      isPrimaryBattery
      isOffline
      isNoData
      vehicleType
      since
    }
  }
`;

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  flex: {
    flex: 1,
  },
  clustererIcon: {
    transform: "translate(45%, -45%)",
    fontWeight: "600",
  },
});

const Live = (props) => {
  const [map, setMap] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [isLiveTracking, setIsLiveTracking] = useState(false);
  const [expired, setExpired] = useState(false);

  const markerInstanceRef = useRef({});
  const multiLineRef = useRef(null);
  const subscriptionRef = useRef({});
  const popUpRef = useRef({});
  const idArray = localStorage.getItem("vehicle").split(",");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [clusterer, setClusterer] = useState();
  const customInfoBox = getCustomPopup(props.google);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    checkToken();
    return () => {
      resetLiveTracking();
      stopSubscriptions();
      clearMapVariable();
    };
  }, []);

  useEffect(() => {
    if (map) {
      multiLineRef.current = new (getMultiLine(props.google))(map);
      handleInitialRequestLive();
      map.setCenter(new props.google.maps.LatLng(22, 80));
    }
  }, [map]);

  useEffect(() => {
    if (selectedVehicle && vehicles.length > 0) {
      animateOneVehicleLive();
    }
  }, [vehicles, selectedVehicle]);

  const isOffline = (timestamp) => {
    const currentTime = Math.round(new Date().getTime() / 1000);
    return currentTime - parseInt(timestamp) > 1800;
  };

  const setupSubscription = (id) => {
    const vehicleobj = vehicles.find((item) => item.id === id);

    subscriptionRef.current = props.client
      .subscribe({
        query: DEVICE_LOCATION,
        variables: {
          deviceId: id,
          snapToRoad: true,
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      })
      .subscribe({
        next({ data }) {
          const newLocation = {
            latitude: data.deviceLiveTracking[0].latitude,
            longitude: data.deviceLiveTracking[0].longitude,
            speed: data.deviceLiveTracking[0].speed,
            timestamp: data.deviceLiveTracking[0].timestamp,
            address: data.deviceLiveTracking[0].address,
            isNoGps: data.deviceLiveTracking[0].isNoGps,
            haltStatus: data.deviceLiveTracking[0].haltStatus,
            idlingStatus: data.deviceLiveTracking[0].idlingStatus,
            isNoData: data.deviceLiveTracking[0].isNoData,
            since: data.deviceLiveTracking[0].since,
          };

          setVehicles((prevData) =>
            prevData.map((vehicle) =>
              vehicle.id === id
                ? {
                    ...vehicle,
                    device: [...vehicle.device, newLocation],
                    pointsReceived: vehicle.pointsReceived + 1,
                  }
                : vehicle
            )
          );

          popUpRef.current.setPosition(
            new props.google.maps.LatLng(
              newLocation.latitude,
              newLocation.longitude
            ),
            newLocation,
            vehicleobj
          );
        },
        error(err) {
          console.error("Subscription error", err);
        },
      });
  };

  const resetLiveTracking = () => {
    setVehicles((prevVehicles) =>
      prevVehicles.map((vehicle) => ({
        ...vehicle,
        device: [],
        pointsReceived: 0,
      }))
    );
  };

  const animateOneVehicleLive = () => {
    const vehicle = vehicles.find((obj) => obj.id === selectedVehicle.id);

    const { device, pointsReceived } = vehicle;
    if (markerInstanceRef.current[vehicle.id] && device.length > 0) {
      const currentData = device[device.length - 1];

      markerInstanceRef.current[vehicle.id].updateMarker(
        {
          lat: parseFloat(currentData.latitude.toFixed(6)),
          lng: parseFloat(currentData.longitude.toFixed(6)),
        },
        {
          status: isOffline(currentData.timestamp)
            ? "offline"
            : currentData.isNoGps
            ? "nogps"
            : currentData.haltStatus
            ? "halt"
            : currentData.idlingStatus
            ? "idle"
            : "running",
          mode: "live",
          isOverspeed: currentData.isOverspeed,
          timestamp: currentData.timestamp,
          speed: currentData.speed,
        },
        3000
      );
    }
  };

  const startSubscription = (vehicle) => {
    setIsLiveTracking(true);
    setupSubscription(vehicle.id);
  };

  const stopSubscriptions = () => {
    setIsLiveTracking(false);
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    }
  };

  const getStatusPriority = (status) => {
    const statusPriority = {
      running: 1,
      idle: 2,
      halt: 3,
      offline: 4,
      nogps: 5,
      isNoData: 6,
    };
    return statusPriority[status] || 7; // Default priority for unknown status
  };

  const handleInitialRequestLive = async () => {
    // setVehicles([]);
    const response = await props.client.query({
      query: GET_MUTLTI_DEVICES_LATEST_LOCATION,
      variables: { deviceIds: idArray },
      fetchPolicy: "network-only",
    });

    let markers = [];

    const res = response.data.getMultiDevicesLatestLocation.map(
      (locationData) => {
        const {
          latitude,
          longitude,
          vehicleNumber,
          address,
          speed,
          timestamp,
          isNoGps,
          haltStatus,
          idlingStatus,
          isOffline,
          uniqueId,
          vehicleType,
          isNoData,
          since
        } = locationData;

        markerInstanceRef.current[uniqueId] = new (getCustomMarker(
          props.google
        ))(
          {
            uniqueId: uniqueId,
            latitude,
            longitude,
            timestamp: moment().unix(),
            speed: 0,
            vehicleType,
            isNoGps,
            haltStatus,
            idlingStatus,
            isOffline,
            isNoData,
            since
          },
          map,
          multiLineRef.current
        );

        markers.push(markerInstanceRef.current[uniqueId]);

        return {
          id: uniqueId,
          vehicleType,
          vehicleNumber: vehicleNumber || "N/A",
          speed: speed || "0",
          time: timestamp
            ? moment.unix(timestamp).format("Do MMMM YYYY h:mm:ss A")
            : "N/A",
          location: address || "N/A",
          device: [
            {
              latitude,
              longitude,
              isNoGps,
              haltStatus,
              idlingStatus,
              isOffline,
              speed,
              timestamp,
              address,
              isNoData,
              since
            },
          ],
          pointsReceived: 1,
        };
      }
    );

    const interpolatedRenderer = {
      render: function ({ count, position }) {
        const svg = window.btoa(ClustererIcon);
        return new window.google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new window.google.maps.Size(80, 55.36),
            anchor: new window.google.maps.Point(50, 30.68),
          },
          label: {
            text: String(count),
            color: "#061B2E",
            fontSize: "14px",
            className: props.classes.clustererIcon,
          },
          title: `Cluster of ${count} events`,
          zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };

    setClusterer(
      new MarkerClusterer({
        map: map,
        markers: markers,
        algorithm: new SuperClusterAlgorithm({ radius: 150 }),
        renderer: interpolatedRenderer,
      })
    );

    const sortedVehicles = res.sort((vA, vB) => {
      const vehicleA = vA.device[0];
      const vehicleB = vB.device[0];
      const statusA = vehicleA.isOffline
        ? "offline"
        : vehicleA.isNoGps
        ? "nogps"
        : vehicleA.haltStatus
        ? "halt"
        : vehicleA.idlingStatus
        ? "idle"
        : vehicleA.isNoData
        ? "nodata"
        : "running";

      const statusB = vehicleB.isOffline
        ? "offline"
        : vehicleB.isNoGps
        ? "nogps"
        : vehicleB.haltStatus
        ? "halt"
        : vehicleB.idlingStatus
        ? "idle"
        : vehicleB.isNoData
        ? "nodata"
        : "running";

      return getStatusPriority(statusA) - getStatusPriority(statusB);
    });

    setVehicles(sortedVehicles);
  };

  const handleRequestLive = async (selectedVehicle) => {
    // setVehicles([]);
    let res = vehicles;

    let markers = [];

    res.forEach((vehicle) => {
      const { vehicleNumber, timestamp, id, vehicleType, device } = vehicle;

      const {
        address,
        haltStatus,
        idlingStatus,
        isNoGps,
        isOffline,
        latitude,
        longitude,
        speed,
        since
      } = device[device.length - 1];

      markerInstanceRef.current[id] = new (getCustomMarker(props.google))(
        {
          uniqueId: id,
          latitude,
          longitude,
          timestamp: moment().unix(),
          speed: speed,
          vehicleType,
          isNoGps,
          haltStatus,
          idlingStatus,
          isOffline,
          since
        },
        map,
        multiLineRef.current
      );

      markers.push(markerInstanceRef.current[id]);
    });

    markerInstanceRef.current[selectedVehicle.id].addhandleClickFunc(
      handleMarkerClick
    );

    clusterer.addMarkers(markers);

    const sortedVehicles = res.sort((vA, vB) => {
      const vehicleA = vA.device[0];
      const vehicleB = vB.device[0];
      const statusA = vehicleA.isOffline
        ? "offline"
        : vehicleA.isNoGps
        ? "nogps"
        : vehicleA.haltStatus
        ? "halt"
        : vehicleA.idlingStatus
        ? "idle"
        : vehicleA.isNoData
        ? "nodata"
        : "running";

      const statusB = vehicleB.isOffline
        ? "offline"
        : vehicleB.isNoGps
        ? "nogps"
        : vehicleB.haltStatus
        ? "halt"
        : vehicleB.idlingStatus
        ? "idle"
        : vehicleB.isNoData
        ? "nodata"
        : "running";

      return getStatusPriority(statusA) - getStatusPriority(statusB);
    });

    setVehicles(sortedVehicles);
  };

  const clearMapVariable = () => {
    if (multiLineRef.current) {
      multiLineRef.current.remove();
    }
    Object.values(markerInstanceRef.current).forEach((marker) => {
      if (marker) {
        marker.setMap(null);
      }
    });
  };

  const checkToken = () => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("exp") < Math.round(new Date().getTime() / 1000)
    ) {
      setExpired(true);
      stopSubscriptions();
    }
    setTimeout(checkToken, 5000);
  };

  const handlePopUp = (vehicle) => {
    const latestData = vehicle.device[vehicle.device.length - 1];
    const status = latestData.isOffline
      ? "offline"
      : latestData.isNoGps
      ? "nogps"
      : latestData.haltStatus
      ? "halt"
      : latestData.idlingStatus
      ? "idle"
      : "running";

    const popup = new customInfoBox(
      new props.google.maps.LatLng(latestData.latitude, latestData.longitude)
    );
    popUpRef.current = popup;
    popUpRef.current.setPopupData({
      vehicleNumber: vehicle.vehicleNumber,
      vehicleModel: vehicle.vehicleType,
      status: status,
      speed: latestData.speed,
      timestamp: latestData.timestamp,
      address: vehicle.location,
      since: latestData.since
    });
    popUpRef.current.setMap(map);
  };

  const handleVehicleLiveTracking = (vehicle) => {
    if (selectedVehicle) stopSubscriptions();
    clearMapVariable();
    // resetLiveTracking();
    // handleInitialRequestLive();
    clusterer.clearMarkers();
    handleRequestLive(vehicle);
    setSelectedVehicle(vehicle);
    handlePopUp(vehicle);
    startSubscription(vehicle);
    map.setZoom(16.5);
    map.setCenter(
      new props.google.maps.LatLng(
        vehicle.device[vehicle.device.length - 1].latitude,
        vehicle.device[vehicle.device.length - 1].longitude
      )
    );
  };

  const handleCloseVehicleLiveTracking = () => {
    if (selectedVehicle) stopSubscriptions();
    clearMapVariable();
    // resetLiveTracking();
    clusterer.clearMarkers();
    popUpRef.current.onRemove();
    handleInitialRequestLive();
    setSelectedVehicle("");
    map.setZoom(5.2);
    map.setCenter(new props.google.maps.LatLng(22, 80));
  };

  const handleMarkerClick = () => {
    popUpRef.current.onAdd();
  };

  return (
    <Grid
      container
      spacing={0}
      className={props.classes.root}
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <Grid item xs={12}>
        <NavBar {...props} />
        <br />
        {!expired || idArray.length === 0 ? (
          <Grid
            container
            spacing={2}
            style={{
              height: "90vh",
              marginLeft: "10px",
              marginRight: "10px",
              width: "98.5vw",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                height: "90vh",
                overflowY: "scroll",
                padding: 2,
                backgroundColor: "#f5f5f5",
                order: isSmallScreen ? 2 : 1,
              }}
            >
              <Box
                style={{
                  marginBottom: 2,
                  padding: 2,
                  borderRadius: 1,
                  boxShadow: 2,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: 1,
                    color: "#333333",
                  }}
                >
                  Vehicle Details
                </Typography>
              </Box>
              {vehicles &&
                vehicles.map((vehicle) => (
                  <VehicleCard
                    key={vehicle.id}
                    vehicle={vehicle}
                    handleVehicleLiveTracking={handleVehicleLiveTracking}
                    selectedVehicle={selectedVehicle}
                    handleCloseVehicleLiveTracking={
                      handleCloseVehicleLiveTracking
                    }
                  />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              style={{ height: "100%", order: isSmallScreen ? 1 : 2 }}
            >
              <Suspense
                fallback={
                  <Fade in={true} timeout={500}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed", // Ensure it covers the entire viewport
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
                        zIndex: 9999, // Make sure it's on top
                      }}
                    >
                      <CircularProgress size={80} color="primary" />
                      <Typography
                        variant="h5"
                        sx={{ marginTop: 2, fontWeight: "bold", color: "#333" }}
                      >
                        Loading...
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#555" }}>
                        Please wait while we load your content.
                      </Typography>
                    </Box>
                  </Fade>
                }
              >
                <Map
                  google={props.google}
                  setMap={(mapInstance) => setMap(mapInstance)}
                  zoom={selectedVehicle ? 25 : 5.2}
                  style={{ minheight: "100%" }}
                />
              </Suspense>
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Typography
              variant="h6"
              style={{
                minHeight: "90vh",
                display: "flex",
                justifyContent: "center",
                color: "#333",
                fontSize: "1.4rem",
                fontWeight: "500",
                margin: "100px auto",
              }}
            >
              The token has expired. You may need to request a new one.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default withApollo(withStyles(styles)(withGoogleMaps(Live)));
