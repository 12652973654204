import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";
import decode from "jwt-decode";
import { validate as isUuid } from "uuid"; // Import the UUID validator
import gql from "graphql-tag";

let GET_TOKEN = gql`
  query GetTokenFromRedis($key: String!) {
    getTokenFromRedis(key: $key) {
      msg
      token
    }
  }
`;

export default async function getApolloClient() {
  const url = window.location.href;
  // var parameters = window.location.search.substring(1).split("&");

  // var temp = parameters[0].split("=");
  // var queryToken = unescape(temp[1]);
  // localStorage.setItem('token', queryToken.replace(/^"(.*)"$/, '$1'))

  var pathArray = url.split("/");
  if (pathArray[4]) {
    if (isUuid(pathArray[4])){
      let res=await fetchTokenFromRedis(pathArray[4]);
      localStorage.setItem("token", res.token);
    }else{
      localStorage.setItem("token", pathArray[4]);
    }
  }

  const token = localStorage.getItem("token");
  if (token) {
    try {
      const { iat, exp, uniqueIds, apiUri, title, httpUri,uniqueId } = decode(token);
      const veh = (Array.isArray(uniqueIds) && uniqueIds.length > 0) ? uniqueIds : [uniqueId];
      localStorage.setItem("iat", iat);
      localStorage.setItem("exp", exp);
      localStorage.setItem("vehicle", veh);
      localStorage.setItem("apiUri", apiUri);
      localStorage.setItem("httpUri", httpUri);
      localStorage.setItem("title", title);
      
    } catch (error) {
      console.log(error);
    }
  }

  // if(localStorage.getItem('exp') > Math.round(new Date().getTime() / 1000))
  // {
  //   console.log('valid')
  // }

  // toko = eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbklkIjozLCJ1c2VybmFtZSI6IndpY2tlZHJpZGUiLCJyb2xlcyI6IkNMSUVOVCIsImlhdCI6MTU5Nzk4ODAzNSwiZXhwIjoxNTk4MDA2MDM1fQ.AItfGHFwn2lmCns00UNgnMlfAfadCgKP68gFniW_GPc

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    // return the headers to the context so httpLink can read them

    /* eslint-disable indent */
    return token
      ? {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        }
      : {
          headers: { ...headers },
        };
    /* eslint-enable indent */
  });

  const wsLink = new WebSocketLink({
    uri: localStorage.getItem("apiUri"),

    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => ({
        Authorization: token,
      }),
    },
  });

  const httpLink = new HttpLink({
    uri: localStorage.getItem("httpUri"),
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: ApolloLink.from([link]),
    cache: new InMemoryCache(),
  });

  return client;
}

export async function fetchTokenFromRedis(id) {
  let key=`share_token_${id}`;
  const apolloClient = new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_TAABI_DEVELOPMENT_URL,
    }),
    cache: new InMemoryCache(),
  });
  try {
    const { data } = await apolloClient.query({
      query: GET_TOKEN,
      variables: { key },
    });

    if (data && data.getTokenFromRedis) {
      const { token, msg } = data.getTokenFromRedis;
      return { token, msg };
    }
  } catch (error) {
    console.error("Error fetching token from Redis:", error);
    return null;
  }
}
